.card {
  box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.15);
}

form {
  width: 90%;
}

form input, form textarea {
  font-size: 1.3rem;
  padding: 1rem 0 !important;
  border: none;
  border-bottom: 2px solid black;
  transition: .3s ease;
}

form input:focus, form textarea:focus {
  border-bottom-color: #007bff;
  outline: none;
}

form button.btn {
  font-size: 1.3rem;
}

.btn-voting {
  border: none;
  background-color: transparent;
  font-size: 1rem;
}

.btn-voting:active {
  color: #007bff;
}